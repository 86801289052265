
import { defineComponent } from "vue";

export default defineComponent({
    name: "Cookies",
    data:function(){
      return{
        showPopUp: true,
      }
    },
    methods:{
      Click(){
        this.showPopUp = false;
        localStorage.setItem('cookies', "cookies");
      }
    }
});

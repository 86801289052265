import { userService } from "../../../DAL/Services/User/user.service";
import { Commit } from "vuex"
import { AuthenticateRequest } from "../../models/AuthenticateRequest";
import { IConnectedLogin } from "../../models/IConnectedLogin";
import { IState } from "../../models/IState";
import { ISubscribe } from "BLL/models/Subscribe";
import { INewPass } from "BLL/models/NewPass";

//on initial load of the module, we go see if we still have an user in the localstorage from a previous session
const userStorage = localStorage.getItem('user');
//we initialise the initialstate
let initialState = { login : null, error: null};

//if we have an user
if(userStorage)
{
    //we parse it
    const user = JSON.parse(userStorage);

    //if we have a TOKEN (which should allways be true)
    if(user.TOKEN)
        //we check if we are authorized
        userService.CheckAuth();

    //and give the value to the initialstate
    initialState = { login : JSON.parse(userStorage), error : null };
}


export interface userState extends IState{
    login:  IConnectedLogin | null,
}

export const userStore = {
    namespaced: true,
    state: initialState,
    getters:{
        isLoggedIn(state: userState){
            return state.login != null;
        },
        loggedId(state:userState){
            return state.login?.LOGIN_ID;
        },
        GetLogin(state:userState){
            return state.login;
        }
    },
    actions:{
        async Logged({commit}:{commit: Commit}): Promise<void>{
            await userService.Logged()
                .then(
                    res => commit('SetLogged', res),
                    error => commit('SetLogged', error))  
        },
        async SignUp({commit}:{commit: Commit}, data : ISubscribe): Promise<void>{
            commit("SetErrorToNull");
            await userService.SignUp(data)
                .catch(error => commit('SignUpFailure', error))
        },
        async ConfirmEmail({commit}:{commit: Commit}, guid : string): Promise<void>{
            commit("SetErrorToNull");
            await userService.ConfirmEmail(guid)
                .catch(error => commit('ConfirmEmailFailure', error))
        },
        async RetrievePass({commit}:{commit: Commit}, email : string): Promise<void>{
            commit("SetErrorToNull");
            await userService.RetrievePass(email)
                .catch(error => commit('RetrievePassFailure', error))
        },
        async CheckIfNewPassGuidExists({commit}:{commit: Commit}, guid : string): Promise<void>{
            commit("SetErrorToNull");
            await userService.CheckIfNewPassGuidExists(guid)
                .catch(error => commit('RetrieveGuidFailure', error))
        },
        async SaveNewPass({commit}:{commit: Commit}, data : INewPass): Promise<void>{
            await userService.SaveNewPass(data)
                .then(
                    res => commit('SaveNewPassSucces', res),
                    error => commit('SaveNewPassError', error))
        },
        async Authenticate({commit}:{commit: Commit}, data : AuthenticateRequest): Promise<void>{
            await userService.Authenticate(data)
                .then(
                    res => commit('AuthenticateSucces', res),
                    error => commit('AuthenticateError', error))
        },
        Logout({commit}:{commit: Commit}){
            commit('Logout');   
        }
    },
    mutations: {
        SetLogged(state : userState, res : string){
            state.error = res;
        },
        SetErrorToNull(state: userState){
            state.error = null;
        },
        RetrieveGuidFailure(state: userState, error: string){
            state.error = error;
        },
        SignUpFailure(state: userState, error: string): void{
            state.error = error;
        },
        ConfirmEmailFailure(state: userState, error: string): void{
            state.error = error;
        },
        RetrievePassFailure(state: userState, error: string): void{
            state.error = error;
        },
        SaveNewPassSucces(state: userState, error: string): void{
            state.error = error;
        },
        SaveNewPassError(state: userState, error: string): void{
            state.error = error;
        },
        AuthenticateSucces(state: userState, data: IConnectedLogin): void{
            state.error = null;
            state.login = data;
            
            //keep info of logged login in local storage
            if (data.TOKEN)
                localStorage.setItem('user', JSON.stringify(data));
        },
        AuthenticateError(state: userState, error: string): void{
            state.login = null;
            state.error = error;
        },
        Logout(state : userState){
            state.login = null;
            localStorage.removeItem('user');
        }
    }
}

import { defineComponent } from "vue";
import store from "../../../BLL/store";
import DynamicPage from '../../../BLL/Tumi/Components/DynamicPage.vue'
import { IResult } from "../../../BLL/Tumi/Models/Models";

export default defineComponent({
    name: "Content",
    emits:["CloseNewProject"],
    components:{
      DynamicPage
    },
    data: function(){
      return{
        PathGet: "/Project/GetEmptyProject" as string,
        PathSet: "/Project/SetProjectView" as string,
        activeObject : null as any,
        error: "" as string,
      }
    },
    methods:{
      async Save(obj: IResult){
        if(obj.errors.length > 0){
          const er = obj.errors.find(i=>i.error == "required");

          if(er)
            this.error = "Those fields are required";
          
          return;
        }

        if(this.PathSet){
          this.activeObject = null;
          const pathSet = this.PathSet; 
          const retour = obj.object;
          await store.dispatch('dynamicStore/setDynamic', {pathSet, retour});
          this.$emit("CloseNewProject");
          this.LoadObject();
        }
      },
      async LoadObject(){
        if(this.PathGet){
          await store.dispatch('dynamicStore/getDynamic',this.PathGet);
          this.activeObject = (store.state as any).dynamicStore[this.PathGet];
        }
      }
    },
    created(){
      this.LoadObject();
    }
});

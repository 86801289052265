import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Nav = _resolveComponent("Nav")!
  const _component_Cookies = _resolveComponent("Cookies")!
  const _component_Home = _resolveComponent("Home", true)!
  const _component_How = _resolveComponent("How")!
  const _component_Search = _resolveComponent("Search")!
  const _component_Contact = _resolveComponent("Contact")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Nav),
    (_ctx.cook)
      ? (_openBlock(), _createBlock(_component_Cookies, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_Home),
    _createVNode(_component_How),
    _createVNode(_component_Search),
    _createVNode(_component_Contact),
    _createVNode(_component_Footer)
  ], 64))
}
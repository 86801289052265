<template>
<div class="bg-black text-white">
<div class="container p-5">
<h1>COOKIEVERKLARING</h1>
Wat zijn cookies?
Een cookie is een klein (tekst)bestandje dat door je browser op je computer wordt opgeslagen wanneer je onze website bezoekt. Deze informatie kan bij je volgende bezoek naar ons worden teruggestuurd. Zo kan onze website je herkennen.

<h3 class="mt-5">Waarom gebruiken wij cookies?</h3>
Cookies zorgen ervoor dat de website snel is en je onze website veilig kan bezoeken. De cookies zorgen er ook voor dat wij kunnen zien hoe de website wordt gebruikt en hoe wij onze website kunnen verbeteren. Daarnaast kunnen cookies van onszelf worden gebruikt om advertenties te tonen die aansluiten op jouw interesses.

<h3 class="mt-5">Welke cookies gebruiken wij?</h3>
Functionele cookies
Deze cookies zijn noodzakelijk om de website te laten werken. Zonder deze cookies kan je en kunnen sommige voorkeuren niet worden bewaard.

<h3 class="mt-5">Google Analytics</h3>
Om de kwaliteit en effectiviteit van onze website te testen maken we gebruik van Google Analytics. Zo kunnen we zien hoe de website wordt gebruikt en hoe we de website kunnen verbeteren. Wij hebben een bewerkersovereenkomst gesloten met Google. Dat betekent dat Google de gegevens die door deze cookies worden verzameld, niet voor eigen doeleinden zal gebruiken.

<h3 class="mt-5">Tracking cookies</h3>
Wij vragen je toestemming om tracking cookies te plaatsen. Op basis van de pagina's die jij hebt bezocht, kunnen wij je advertenties laten zien waarvan we denken dat deze interessant voor jou zijn.

<h3 class="mt-5">Hoe kan ik mijn gegevens inzien, corrigeren of verwijderen?</h3>
Je kunt jouw persoonsgegevens inzien, corrigeren of laten verwijderen. Stuur dan een e-mail naar: info@projectlifekey.com

<h3 class="mt-5">Hoe kan ik cookies uitschakelen of verwijderen?</h3>
Je kunt ervoor kiezen geen toestemming te geven voor het plaatsen van cookies. In de internetbrowser kan je ook instellen dat cookies geblokkeerd worden. Het verschilt per browser hoe je dit kan instellen, de meeste browsers geven hier uitleg over in de help-functie. Als je cookies blokkeert, kan het zijn dat onze website minder goed werkt.
</div>
</div>
</template>

<style scoped>

</style>
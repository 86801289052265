
import { Config } from "../../../DAL/Config";
import { defineComponent } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
    name: "ObjectCode",
    data: function(){
      return{
        id: "" as string
      }
    },
    methods:{
      Download(){
        window.open(Config.Url() + '/Project/GetFiles?code=' + this.id);
      }
    },
    created(){
      this.id = String(useRoute().params.id);
    }
});


import { defineComponent } from 'vue';
import Contact from '../../views/Contact/Contact.vue';

export default defineComponent({ 
  name: 'How',
  components:{
    Contact
  },
  data: function(){
    return{

    }
  }
});


import store from "../../../BLL/store";
import { defineComponent } from "vue";
import DynamicPage from '../../../BLL/Tumi/Components/DynamicPage.vue' 
import { IResult } from "../../../BLL/Tumi/Models/Models";
import { validateEmail } from "../../../BLL/helpers/fonctions";

export default defineComponent({
    name: "ActivateCode",
    components:{
      DynamicPage
    },
    data: function(){
      return{
        object: {} as any,
        error: "" as string,
        pass: "" as string
      }
    },
    methods:{
      async Save(ret :IResult){
        this.error = "";
        this.pass = "";

        if(ret.errors.length > 0)
        {
          if(ret.errors.find(i=>i.error == 'required'))
            this.error = "Those fields are required";

          return;
        }

        if(!validateEmail(ret.object.CONTACT_EMAIL))
        {
          this.error = "Email is not valid.";
          (this.$refs['form'] as any).ClasseErreur('CONTACT_EMAIL');
          return;
        }

        this.object = null;
        await store.dispatch('dynamicStore/setDynamic',{pathSet:'/Contact/SendContact', retour: ret.object});
        this.Refresh();
        this.Next();
      },
      Next(){
        this.pass = "Thanks, your message has been sent";
      },
      async Refresh(){
        await store.dispatch('dynamicStore/getDynamic','/Contact/GetEmptyContact');
        this.object = (store.state as any).dynamicStore['/Contact/GetEmptyContact'];      
      }
    },
    async created(){
      this.Refresh();
    }
});

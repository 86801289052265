
import store from "../../BLL/store";
import { defineComponent } from "vue";
import { Subscribe } from "../../BLL/models/Subscribe";
import { validateEmail } from "../../BLL/helpers/fonctions";

export default defineComponent({
    name: "SignUp",
    data: function(){
      return{
        mail : "" as string,
        pass: "" as string,
        pass2: "" as string,
        error: "" as string,
        waiting: false as boolean,
        next: false as boolean
      }
    },
    methods:{
      async SignUp(){
        this.error = "";

        if(!this.mail || !this.pass || !this.pass2){
          this.error = "All fields are required.";
          return;
        }  

        if(this.pass != this.pass2){
          this.error = "Passwords don't match.";
          return;
        }

        if(!validateEmail(this.mail)){
          this.error = "Please enter a valid email."
          return;
        }

        this.waiting = true;

        await store.dispatch('userStore/SignUp', new Subscribe(this.mail, this.pass));

        this.waiting = false;

        const error = (store.state as any).userStore.error;

        if(error)
        {
          this.error = error;
          return;
        }

        this.SignedUp();
      },
      async SignedUp()
      {
        //Logic when logged in
        this.next = true;
      }
    }
});


import store from "../../BLL/store";
import { AuthenticateRequest } from "../../BLL/models/AuthenticateRequest";
import { defineComponent } from "vue";

export default defineComponent({
    name: "Home",
    data: function(){
      return{
        mail : "" as string,
        pass: "" as string,
        error: "" as string,
        waiting: false as boolean
      }
    },
    methods:{
      GoToPortal(){
        this.$router.push('/Portal');
      },
      Logout(){
        this.error = "";
        store.dispatch('userStore/Logout', new AuthenticateRequest(this.mail, this.pass));
        this.$forceUpdate();
      },
      async Login(){
        this.error = "";
        
        if(!this.mail || !this.pass)
        {
          this.error = "All fields are required.";
          return;
        }  

        this.waiting = true;
        await store.dispatch('userStore/Authenticate', new AuthenticateRequest(this.mail, this.pass));
        this.waiting = false;
        const error = (store.state as any).userStore.error;

        if(error)
        {
          this.error = error;
          return;
        }

        this.LoggedIn();
      },
      async LoggedIn()
      {
        this.GoToPortal();
      }
    },
    computed:{
      Auth(){
        return store.getters['userStore/isLoggedIn'];
      }
    },
    created()
    {
      if(this.Auth)
        this.GoToPortal();
    }
});


import store from "../../../BLL/store";
import { defineComponent } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
    name: "ConfirmEmailTiers",
    data: function(){
      return{
      }
    },
    created(){
      const guid = useRoute().params.id;
      store.dispatch('dynamicStore/getDynamic','/project/ConfirmThirdPartyEmail?codeThirdParty=' + guid);
    }
});


import store from '../../../BLL/store';
import { defineComponent } from 'vue';

export default defineComponent({ 
  name: 'How',
  data:function(){
    return{
      deactivate : false as boolean
    }
  },
  methods:{
    No(){
      store.dispatch('dynamicStore/getDynamic','/poll/down');
      this.deactivate = true;
    },
    Yes(){
      store.dispatch('dynamicStore/getDynamic','/poll/up')
      this.deactivate = true;
    }
  }
});


import store from '../../../BLL/store';
import { defineComponent } from 'vue';
import {ILogin} from '../../../BLL/models/Login'

export default defineComponent({
  name: 'Nav',
  methods:{
    Logout(){
        store.dispatch('userStore/Logout');
        this.$router.push('/');
    },
    GetLogin(){
      return (store.getters['userStore/GetLogin'] as ILogin)?.LOGIN_EMAIL;
    }
  }
});

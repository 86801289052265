
import store from "../../BLL/store";
import { defineComponent } from "vue";

export default defineComponent({
    name: "Home",
    data: function(){
      return{
        mail : "" as string,
        error: "" as string,
        waiting: false as boolean,
        next: false as boolean
      }
    },
    methods:{
      async SendMail(){
        this.error = "";

        if(!this.mail)
        {
          this.error = "Email field is required";
          return;
        }  

        this.waiting = true;
        await store.dispatch('userStore/RetrievePass', this.mail);
        this.waiting = false;

        const error = (store.state as any).userStore.error;

        if(error)
        {
          this.error = error;
          return;
        }

        this.EmailSent();
      },
      async EmailSent(){
        //Logic when logged in
        this.next = true;
      }
    }
});


import {defineComponent} from "vue";
import store from "../../../BLL/store";
import NewProject from "../../components/Project/NewProject.vue"
import {Iproject} from "../../../BLL/models/Project/Iproject"
import LoadProject from "../../components/Project/LoadProject.vue"
import NavigationPortal from "../../components/Nav/NavigationPortal.vue"
import Footer from "@/components/Home/Footer.vue";
import {getDate_ddmmyyyy} from "../../../BLL/helpers/fonctions"

export default defineComponent({
    name: "Home",
    components:{
    NewProject,
    LoadProject,
    NavigationPortal,
    Footer
  },
    data:function(){
      return{
        newProject: false as boolean,
        projects: null as Iproject[]|null,
        activeObject : {} as  Iproject,
        activeLi: -1 as number,
        spinSend: false,
        spinConfirm: false
      }
    },
    methods:{
      ParseDate(date: string){
        return getDate_ddmmyyyy(new Date(date));
      },
      async DeleteProject(id: number){
        await store.dispatch('dynamicStore/getDynamic', '/project/DeleteProject?idProject='+ id);
        this.LoadProjects();
      },
      async SendCode(id: number){
        this.spinSend = true;
        store.dispatch('dynamicStore/getDynamic', '/project/SendEmailToClient?idProject='+ id);
        setTimeout(() => {
          this.spinSend = false;
        }, 2000);
      },
      async SendConfirm(id: number){
        this.spinConfirm = true;
        store.dispatch('dynamicStore/getDynamic', '/project/SendEmailToFollower?idProject='+ id);
        setTimeout(() => {
          this.spinConfirm = false;
        }, 2000);
      },
      Reload(){
        this.activeLi = -1;
      },
      async SelectProject(id: number){
        if(this.activeLi == id){
          this.activeLi = -1;
          return;
        }

        this.activeLi = id;
        await store.dispatch('dynamicStore/getDynamic','/project/GetProject?id=' + id);
        this.activeObject = (store.state as any).dynamicStore['/project/GetProject?id=' + id];
      },
      New(){
        this.newProject = !this.newProject;
      },
      CloseNewProject(){
        this.newProject = false;
        this.LoadProjects();
      },
      async LoadProjects(){
        await store.dispatch('dynamicStore/getDynamic','/project/GetProjectsFromLogin');
        this.projects = (store.state as any).dynamicStore['/project/GetProjectsFromLogin'];
      }
    },
    async created(){
      this.LoadProjects();
    }
});


import store from '../../../BLL/store';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Logo',
  data:function(){
    return{
      input: "test" as string
    }
  }
});

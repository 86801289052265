
import store from "../../../BLL/store";
import { defineComponent } from "vue";

export default defineComponent({
    name: "ActivateCode",
    data: function(){
      return{
        mail : "" as string,
        error: "" as string,
        waiting: false as boolean,
        succes:"" as string
      }
    },
    methods:{
      async SendMail(){
        this.error = "";

        if(!this.mail)
        {
          this.error = "Code is required";
          return;
        }  

        this.waiting = true;
        await store.dispatch('dynamicStore/getDynamic', '/project/ActivateCode?code=' + this.mail);
        this.waiting = false;

        const error = (store.state as any).dynamicStore['/project/ActivateCode?code=' + this.mail];

        if(error)
        {
          this.error = error;
          return;
        }

        this.CodeActivated();
      },
      async CodeActivated()
      {
        this.succes = "Code activated! Further instructions will be sent to all the parties per mail.";
      }
    }
});


import store from '../../../BLL/store';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Nav',
  methods:{
    ToContact(){
        const element = document.getElementById('contact');
        
        if(element)
        {
          const y = element.getBoundingClientRect().top + window.pageYOffset + (-50);
          window.scrollTo({top: y, behavior: 'smooth'});
        }
    },
    ToHow(){ 
        const element = document.getElementById('how');

        if(element)
        {
          const y = element.getBoundingClientRect().top + window.pageYOffset + (-50);
          window.scrollTo({top: y, behavior: 'smooth'});
        }
    },
    Logout(){
        store.dispatch('userStore/Logout');
        this.$forceUpdate();
    }
  },
  computed:{
    Logged(){
      return store.getters['userStore/isLoggedIn']
    }
  }
});

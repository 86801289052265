
import { defineComponent } from 'vue';
import Home from '../../components/Home/Header.vue'
import How from "../../components/Home/How.vue"
import Nav from "../../components/Nav/Navigation.vue"
import Footer from "../../components/Home/Footer.vue"
import Search from "../../components/Home/Search.vue"
import Contact from "../../components/Home/Contact.vue"
import Cookies from "../../components/Home/Cookies.vue"

export default defineComponent({
  name: 'Logo',
  components:{
    Home,
    How,
    Nav,
    Footer,
    Search,
    Contact,
    Cookies
  },
  data:function(){
    return{
      cook: true
    }
  },
  created(){
    if(localStorage.getItem('cookies'))
      this.cook = false;
  }
});

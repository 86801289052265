import {createStore} from 'vuex'
import {userStore} from './User/user.store'
import {dynamicStore} from './Dynamic/dynamic.store'

export default createStore({
  modules: {
    userStore : userStore,
    dynamicStore : dynamicStore,
  }
})
